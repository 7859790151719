@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#root {
  min-height: 100vh; 
  height: 100%;
  width: 100%;
}

button {
  font-family: 'Source Sans Pro', sans-serif;
}

button:focus, a:focus {
  outline:none;
}

@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size: 16px;
  }
}