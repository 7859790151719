@import '/src/util.scss';

.backdrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background: linear-gradient(
    $choices-blue, 
    #42A5F5 
  );
}

@media (max-width: 899px) {
  .backdrop {
    margin-bottom: 3rem;
    bottom: -45%;
  }
} 

.takeWrapAway {
  white-space: nowrap;
}

.legalParagraph {
  font-family: "Times New Roman", serif;
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
  margin: 0%;
  padding: 0.5rem;
}

.modalWindow {
  background-color: $choices-white;
  display: flex;
  flex-direction: column;
  max-width: 85%;
  max-height: 62vh;
  padding: 1%;
  overflow-y: auto; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.377);
}

.modalWindow::-webkit-scrollbar {
  width: 1.5rem;
  background: rgba(102, 98, 98, 0.486);
}

.modalWindow::-webkit-scrollbar-track {
  background: linear-gradient(
    rgba(102, 98, 98, 0.123), 
    rgb(105, 102, 102)
  );  
}

.modalWindow::-webkit-scrollbar-thumb {
  background-color: $choices-grey-dark-transparent;
  border: $choices-blue .1rem solid;
}

.tableLayout {
  th, td {
  border: 2px solid black;
  padding: 3px;
  font-family: "Times New Roman", serif;
  vertical-align: top;
  text-align: left;
}
  width: 100%;
  border: 2px solid black;
  font-family: "Times New Roman", serif;
  border-collapse: collapse;
}

.tableHeader {
  font-weight: 600;
}

.btnContainerSubscribe {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  opacity: 0.5;
  &.checked {
    opacity: 1;
  }

  button {
      background: $choices-blue;
      color: $choices-white;
  }

  button:active {
    transition: all .2s linear;
    background: $choices-white;
    color: $choices-blue;
  }
}


.btnContainer {
  min-width: 6.5rem;
  max-width: 6.5rem;
  min-height: 2.25rem;
  border: solid 2px $choices-blue;
  background: $choices-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  opacity: 0.5;
  &.checked {
    opacity: 1;
  }
}
 
.btn {
  display: flex;
  justify-content: center;
  background: none;
  border: none;
  height: 100%;
  width: 100%;
  color: $choices-white;
  font-size: 1rem;
  opacity: 0.5;
  &.checked {
    cursor: pointer;
    opacity: 1;
  }

  button:active {
    transition: all .2s linear;
    background: $choices-blue;
    color: $choices-white;
  }
}

.centerContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.myLastBtn {
  position: relative;
  background: none;
  border: none;
  height: 100%;
  width: 100%;
  color: $choices-blue;
  font-size: 1rem;

  .checked{
    cursor: pointer;
  }
  
} 

.myCheckbox{
  width: 1.25rem;
  height: 1.25rem;
}

.footer {
  display: flex; 
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  padding-top: 1%;
  
}

