@import '/src/util.scss';

.mobileFilterBanner {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $choices-blue;
    width: 100%;
    padding: 0.5rem 0 2rem 0;
    z-index: 10;
    font-weight: bold;
    font-size: $font-small;
}

.bannerText {
    color: $choices-white;
    margin-left: 1rem;
    white-space: nowrap;
}

.bannerBtn {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    border: solid $choices-white 2px;
    background-color: $choices-blue;
    padding: 0.25rem 0.5rem;
    background: none;
    margin-top: 0.18rem;
    border-radius: 10px;
    cursor: pointer;
}

@mixin filterContainer() {
    min-width: 21rem;
    background-color: $choices-blue;
    width: 100%;
    z-index: 4;
    bottom: 3rem;
    left: 0;
    display: flex;
    flex-direction: column;
    height: max-content;
    padding-bottom: 2rem;
    padding-right: 2rem;
}

@mixin filterElementContainer() {
    display: flex;
    flex-direction: column;
    min-width: max-content;
    max-width: 21rem;
    padding: 1rem;
}

.filterContainer {
    @include filterContainer();
    animation: slideUp .2s ease-in-out;
}

.dynamicFilterContainer {
    @include filterContainer();
    display: none;
}

.filterElementContainer {
    @include filterElementContainer();
}

.disabledFilterElementContainer {
    @include filterElementContainer();
    filter: brightness(0.8);
    opacity: 0.8;
}

.filterElementLabel {
    width: 100%;
    color: $choices-white;
    text-transform: uppercase;
}

.filterSelectElement {
    position: relative;
    width: 100%;
    border: 1px solid $choices-grey-transparent;
    min-width: max-content;
    max-width: 18rem;
}

.filterSelectElement::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1.2rem;
    padding: .5rem .75rem;
    border: 1px solid $choices-grey;
    background: $choices-white;
    color: $choices-white;
    ;
    background-color: $choices-navy;
    display: flex;
    align-items: center;
    pointer-events: none;
}

.filterSelectOption {
    background-color: $choices-white;
}

.filterSelectText {
    appearance: none;
    text-transform: uppercase;
    background: $choices-white;
    color: $choices-navy;
    width: 100%;
    padding: .7rem .7rem;
    font-size: 1rem;
    font-weight: 600;
}

@mixin filterInput() {
    border: 1px solid $choices-grey;
    background: $choices-white;
    color: $choices-navy;
    padding: .7rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    appearance: none;
    max-width: 16rem;
}

.filterInput {
    @include filterInput();
}

.disabledFilterInput {
    @include filterInput();
    opacity: 0.8;
    color: $choices-grey
}

.buttonContainer {
    justify-content: space-evenly;
    display: inline-flex;
}

.submitSearchBtn {
    background: $choices-white;
    border: solid $choices-white 2px;
    width: min-content;
    white-space: nowrap;
    color: $choices-blue;
    font-size: 1.25rem;
    font-weight: 200;
    cursor: pointer;
    margin-top: 1rem;
    margin-left: 1rem;
}

.submitSearchBtn:hover {
    background: $choices-blue;
    border: solid $choices-white 2px;
    color: $choices-white;
    transition: all .15s ease-in-out;
}

.clearFiltersBtn {
    background: $choices-blue;
    border: solid $choices-white 2px;
    white-space: nowrap;
    color: $choices-white;
    font-size: 1.25rem;
    font-weight: 200;
    cursor: pointer;
    margin-top: 1rem;
    margin-left: 1rem;
}

.clearFiltersBtn:hover {
    background: $choices-white;
    border: solid $choices-blue 2px;
    color: $choices-blue;
    transition: all .15s ease-in-out;
}

// Remove default appearance of type="number" from input field.
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.inputIcon {
    position: absolute;
    margin-top: 2rem;
    margin-left: 16rem;
    z-index: 2;
}

.radiusInputText {
    position: absolute;
    margin-top: 2rem;
    margin-left: 12rem;
    z-index: 2;
    font-size: 1.2rem;
    font-weight: 600;
    color: $choices-navy;
}

.checkboxesContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-height: 60vh;
    overflow-y: scroll;
    border-bottom: solid 1px $choices-white;
}

// Chrome / Safari / Edge Scrollbar Styles
.checkboxesContainer::-webkit-scrollbar {
    width: 0.5rem;
}

.checkboxesContainer::-webkit-scrollbar-thumb {
    background-color: $choices-white;
    border-radius: 20px;
}

// Firefox Scrollbar Styles
.checkboxesContainer {
    scrollbar-width: thin;
    scrollbar-color: $choices-white;
}

.checkboxesTitle {
    font-size: 1rem;
    text-transform: uppercase;
    color: $choices-white;
    margin-bottom: 0.5rem;
}

@mixin checkboxContainer() {
    display: flex;
    align-items: center;
    padding-block: 0.5rem;
}

.checkboxContainer {
    @include checkboxContainer();
}

.disabledCheckbox {
    @include checkboxContainer();
    opacity: 0.5;
}

.checkbox {
    appearance: none;
    border: solid $choices-white 2px;
    background: $choices-blue;
    height: 1.2rem;
    width: 1.2rem;
    cursor: pointer;
}

.checkbox:checked {
    background: $choices-white;
    box-shadow: inset 0 0 1px 1px $choices-blue;
}

.checkboxLabel {
    color: $choices-white;
    margin-left: 1rem;
}

.filterSummary {
    font-size: 1.2rem;
}

.filterSummaryItem {
    color: $choices-blue;
}

.mobileActivated {
    rotate: -90deg;
    transition: .4s;
}

// Animations
@keyframes slideUp {
    0% {
        transform: translateY(300px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

// Media Queries
@media (min-width: 600px) {
    .checkboxesContainer {
        max-height: none;
        overflow-y: auto;
        border-bottom: none;
    }
}

@media (min-width: 1200px) {
    .mobileFilterBanner {
        display: none;
    }

    .dynamicFilterContainer {
        display: flex;
        position: relative;
        top: 0;
        width: 40%;
        left: 0;
        padding: 1rem 1rem 2rem 0;
        max-width: 21rem;
    }
}