@import '/src/util.scss';

.pageContainer {
    display: flex;
    min-height: max-content;
    margin-bottom: 3rem;
    width: 100vw;
    justify-content: center;
    min-height: 60vh;
}

.contentContainer {
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 2rem;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 2rem;
    h1 {
      color: $choices-blue;
      font-size: max(3rem, 4vw);
      font-weight: 200; 
      text-align: center; 
    }
    div {
        display: flex;
        min-width: max-content;
        align-items: center;
        padding: 0 1rem;
    }
}

.image {
    display: flex;
    width: 100%;
    max-width: 30rem;
    img {
        min-width: 100%;
        height:100%;
        width: 100%;
        object-fit: contain;
    }
}

@media (min-width: 600px) {
    .contentContainer {
        flex-flow: row nowrap;
        max-width: 60rem;
    }
    .text {
        width: 40%;
    }
    .image {
        width: 50%;
    }
}