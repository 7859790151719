@import '/src/util.scss';

.listContainer {
    color: $choices-grey;
    font-size: max(1.2rem, 1.2vw);
}
.introduction {
    padding: 0;
    margin: 0;
}

.termsLink {
    color: $choices-grey;
}