@import '/src/util.scss';

.formContainer {
    width: 100%;
    height: 100%;
}

.formItem {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.inputContainer {
    width: 49%;
}

.accountOptions {
    display: flex;
    flex-flow: column wrap;
}

.passwordContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .formItem:nth-child(-n + 2) {
        width: 49%;
    }

    .formItem:nth-child(3) {
        width: 100%;
    }
}

.inputIcon {
    position: absolute;
    margin-top: 3.35rem;
    margin-left: 0.75rem;
    transition: fill .2s ease-out;
}

.passwordContainer input[type="password"] {
    text-indent: 1.25rem;
}

@mixin formTitle {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
}

.subTitle {
    @include formTitle();
    color: $choices-blue;
}

.darkTitle {
    @include formTitle();
    color: $choices-navy-dark;
    margin-top: 0;
}

.deleteContainer {
    color: $choices-navy-dark;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@mixin accountBtn($color) {
    border: solid $color 2px;
    padding: 0 0.25rem;
    max-width: fit-content;

    button {
        color: $color;
        white-space: nowrap;
    }
}

.deleteBtn>div {
    @include accountBtn($choices-navy-dark);
}

@mixin deleteInteraction {
    border: solid $choices-red 2px;
    box-shadow: 0 0 1px 1px $choices-red-transparent;

    button {
        color: $choices-red;
    }
}

.deleteBtn>div:hover {
    @include deleteInteraction();
}

.deleteBtn>div:active {
    background-color: $choices-red;
    @include deleteInteraction();
}

.submitContainer {
    padding-block: 1.5rem;
    display: flex;
    flex-direction: column;
}

.submitBtn>div {
    height: max-content;
    min-width: fit-content;
    padding: 0.5rem;

    button {
        white-space: nowrap;
        font-size: 1.5rem;
    }
}

.disclaimer {
    padding-block: 1rem;
    padding-right: 1rem;
    color: $choices-grey;
}

.warning {
    margin: 0;
    padding-block: 0.375rem
}

.positive {
    fill: $choices-green;
}

.negative {
    fill: $choices-red;
}

@mixin bannerMessage($backgroundColor) {
    position: fixed;
    z-index: 4;
    top: 4rem;
    left: 0;
    padding: 0 1rem;
    color: $choices-white;
    background-color: $backgroundColor;
    width: 100vw;

    button {
        background: none;
        border: none;
        color: $choices-white;
        position: absolute;
        top: 50%;
        right: 3.25rem;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

.successMessage {
    @include bannerMessage($choices-green);
    animation: fadeInOut 2s ease-out, ;
}

.errorMessage {
    @include bannerMessage($choices-red);
    animation: slideDown .2s ease-out;

    ul {
        max-width: 80%;
        padding-inline-start: 0.75rem;
        font-size: 1.25rem;

        li {
            font-weight: 200;
        }
    }

    ul>li:first-child {
        margin-top: 0.5rem;
    }
}

.animate {
    animation: flash .2s alternate infinite;
}

@media (max-width: 450px) {
    .inputContainer {
        width: 100%;
    }

    .passwordContainer {
        .formItem:nth-child(-n + 2) {
            width: 100%;
        }
    }
}

@media (min-width:600px) {

    .errorMessage,
    .successMessage {
        top: 11.25rem;

        ul,
        p {
            padding-left: 3rem;
        }
    }

    .deleteContainer {
        grid-column: 2/3;
        grid-row: 2/2;
        padding-top: 1rem;
    }

    .disclaimer {
        padding: 0 3rem;
    }
}

@media (min-width:661px) {

    .errorMessage,
    .successMessage {
        top: 8rem;
    }
}

@media (min-width: 1400px) {
    .subTitle,
    .darkTitle {
        font-size: 1.2rem;
        white-space: nowrap;
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-4rem);
        z-index: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes flash {
    0% {
        transform: scale(.75);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}