@import '/src/util.scss';

.locations {
    display: flex;
    flex-flow: row wrap;
    color: $choices-grey;
    font-size: max(1.2rem, 1.2vw);
}

.addressColumn {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    p {
        margin: 0;
        text-decoration: underline;
    }
}

.distanceMeta {
    padding-bottom: 1.5rem;
    white-space: nowrap;
    span {
        padding-left: 0.5rem;
    }
}

.locationMeta {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.directionsBtn {
    padding-top: 1.5rem;
    z-index: 3;
    div {
        height: max-content;
        min-width: 100%;
        padding-block: 0.5rem;
        margin-bottom: 1rem;
        background-color: $choices-white;
    }
    button {
        white-space: nowrap;
    }
}

.phoneMeta {
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    white-space: nowrap;
    span {
        padding-left: 0.5rem;
    }
    a {
        color: $choices-grey;
        text-decoration: none;
    }
}

@media (min-width: 900px) {
    .locationMeta {
        width: 57.5%;
    }
}
