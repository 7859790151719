@import '/src/util.scss';

.details {
    max-width: 40rem;
}

.discountComments {
    font-size: max(1.5rem, 1.5vw);
    color: $choices-grey;
}

.location {
    display: flex;
    flex-direction: column;
    font-size: max(1.2rem, 1.2vw);
}

.locationContent {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
}

.locationMeta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: max(1.2rem, 1.2vw);
    color: $choices-grey
}

.contactMeta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: max(1.2rem, 1.2vw);
    padding-right: 3rem;
    color: $choices-grey
}

.btnContainer {
    margin-top: 2rem;
    z-index: 3;
    div {
        min-width: max-content;
        padding: 0.25rem 0.5rem;
        background-color: $choices-white;
        button {
            font-size: max(1.2rem, 1.2vw);
            white-space: nowrap;
        }
    }
}

.phoneNumber {
    white-space: nowrap;
    a {
        text-decoration: none;
        padding-left: 0.5rem;
        color: $choices-grey;
    }
}

.locationAddress {
    display: flex;
    flex-direction: column;
    text-decoration: underline;
}

.distance {
    white-space: nowrap;
    span {
        padding-left: 0.5rem;
    }
}
