@import '/src/util.scss';

.title {
    font-weight: 200;
    font-size: 2.5rem;
    color: $choices-blue;
    margin: 0;
}

.inputContainer {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 5rem;
}

.input {
    border: solid $choices-input-border 1px;
    background: $choices-input-fill;
    height: 100%;
    padding: 0 1rem;
}

.phoneInput {
    display: flex;
    align-items: center;
    border: solid $choices-input-border 1px;
    background: $choices-input-fill;
    height: 100%;
    padding: 0 0.5rem;
    color: $choices-grey;
    white-space: nowrap;
    min-width: min-content;
}

@mixin phoneMarginTop {
    margin-top: 0.2rem;
}

.areaCode {
    border: none;
    background: none;
    min-width: 1.5rem;
    max-width: 1.8rem;
    @include phoneMarginTop();
    color: black;
}

.lineNumber {
    border: none;
    background: none;
    width: 2.5rem;
    @include phoneMarginTop();
    color: black;
}

.hyphen {
    padding: 0 0.1rem;
}

.countryCode {
    border: none;
    background: none;
    min-width: 1.4rem;
    max-width: 1.6rem;
    @include phoneMarginTop();
    color: black;
}

.prefix {
    border: none;
    background: none;
    min-width: 1.4rem;
    max-width: 1.6rem;
    @include phoneMarginTop();
    margin-left: 0.25rem;
    color: black;
}

.input:focus, .checkbox:focus, .phoneInput:focus {
    border: solid $choices-blue 1px;
    box-shadow: 0 0 0.2rem 0.2rem $choices-blue-transparent;
    outline: none;
}

.label {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    color: $choices-blue;
    font-weight: 600;
}
.checkboxContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 5rem;
}
.checkboxLabel {
    color: $choices-grey;
    padding-left:5%;
}
.checkbox {
    width: 1.5rem;
    height:1.5rem;
    min-width: 1.5rem;
    min-height:1.5rem;
    margin: 0;
    border: solid $choices-input-border 1px;
    background: $choices-input-fill;
}

@media (min-width: 1400px) {
    .title {
        font-size: 3.5rem;
    }
    .label, .checkboxLabel {
        font-size: 1.2rem;
    }
    .label {
        padding-bottom: 0.25rem;
    }
}