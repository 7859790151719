@import '/src/util.scss';

.iconLink {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: currentColor;
    color: $choices-blue;
    font-size: 1rem;

    svg {
        font-size: 1.25rem;
        margin-right: .5rem;
        width: 1.25rem;
        height: 1.25rem;
    }    
}
.iconLink:hover {
    color: $choices-navy-dark;
    transition: all .25s ease-in-out;
}