@import '/src/util.scss';

.landingTop {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: 50vh;
    z-index: 2;
}
.landingTopTitle {
    width: 100%;
    text-align: center;
    @include choices-title($choices-blue, $font-large, 300);
    margin-top: -3rem;
    margin-bottom: 3rem;
}
.searchFormContainer {
    width: 100%;
    padding: 0 20%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.iconLinksContainer {
    margin-top: 1rem;
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.landingBottomHalfContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $choices-blue;
    padding: 2rem;
}
.landingBottomContent {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 2rem 2rem 0rem;
}
.landingBottomHeader {
    margin: 0;
    padding: 2rem 0;
    @include choices-title($choices-white, $font-large, 300);
}
.resourceCenterCardContainer {
    width: 30%;
    height: 100%;
    padding: .5rem;
}
.articleSections {
    margin-top: 2rem;
    max-width: 100%;
    height: 100%;
    padding: 0 2rem;
}
.articleSections:last-of-type {
    margin-bottom: 2rem;
}
.articleSectionTitle {
    width: 100%;
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: .625rem;
    color: $choices-blue;
}
.articleSectionsCardContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
@media (min-width: 600px) {
    .pageContainerTwo {
        margin-top: 10rem;
    }
}
@media (min-width: 733px) {
    .pageContainerTwo {
        margin-top: 8.5rem;
    }
}
@media (min-width: 1400px) {
    .landingBottomHalfContainer {
        padding: 2rem 8rem;
    }
    .articleSections {
        padding: 1.25rem 8rem;
    }
}
@media (max-width: 500px) {
    .landingTopTitle {
        font-size: 2.5rem;
    }
    .searchFormContainer {
        padding: 0 10%;
    }
}
@media (max-width: 600px) {
    .resourceCenterCardContainer {
        width: 100% !important;
    }
    .resourceCenterCardContainer:last-of-type {
        display: flex;
    }
}
@media (max-width: 750px) {
    .iconLinksContainer>* {
        flex-wrap: wrap;
        width: 40%;
        margin: 0.5rem;
    }
    .landingBottomHalfContainer {
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
    }
    .landingBottomContent {
        width: 100%;
        text-align: center;
        padding: 1.25rem;
    }
    .landingBottomContent>div:first-of-type {
        justify-content: center;
    }
    .landingBottomHeader {
        font-size: $font-med;
        padding: 1.25rem;
    }
    .articleSections {
        padding: 0 .5rem;
    }
}
@media (max-width: 900px) {
    .iconLinks {
        width: 70%;
    }
}
@media (max-width: 1000px) {
    .articleSections {
        flex-wrap: wrap;
    }
    .resourceCenterCardContainer {
        width: 45%;
    }
    .resourceCenterCardContainer:last-of-type {
        display: none;
    }
}

// searchResults Section Below: 
.searchResultsContainer {
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.searchResultsTopBar {
    width: 100%;
    background: $choices-blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    height: 60px;
    padding: 0 6rem;
}
.searchResultsTopBar a {
    color: $choices-white;
}
.resultsTopBarLeftColumn {
    width: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 5%;
    order: 1;
}
.resultsTopBarRightColumn {
    width: 45%;
    order: 1;
}
.searchResultsCardContainer {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 6rem;
}
.cardsDisplay {
    width: 100%;
}
.searchResultsCard {
    box-sizing: border-box;
    width: 50%;
    order: 1;
}
.extendedCardInfo {
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-left: 1rem;
    order: 1;
}
.cardDetails {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: .5rem;
}
.extendCardIcon {
    svg {
        width: 1.25rem;
        margin-right: .5rem;
    }
}
.extendCardIcon:hover,
.extrendCardIcon:active {
    transition: all .2s linear;
    color: $choices-blue;
    cursor: pointer;
}
.flexParent {
    width: 100%;
    display: flex;
}
.extendCardTitle {
    font-size: 1.5rem;
}
.extendCardIcon {
    width: 100%;
}
.extendCardFavorite,
.extendCardEmail {
    width: 50%;
}
.searchResultsArticleTitle {
    padding: 0 6rem;
    width: 100%;
    @include choices-title($choices-white, $font-med, 400);
}
.resourceCenterFeaturedSection {
    background: $choices-blue;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.resourceCenterFeaturedCards {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 6rem 2rem 6rem;
}
.searchResultsCardContainerBottom {
    width: 30%;
}
@media (max-width: 1400px) {
    .searchResultsTopBar {
        padding: 0 4rem;
    }
    .searchResultsCardContainer {
        padding: 2rem 4rem;
    }
    .searchResultsArticleTitle {
        padding: 0 4rem;
    }
    .resourceCenterFeaturedCards {
        padding: 0 4rem 2rem 4rem;
    }
    .searchResultsCardContainerBottom {
        width: 45%;
    }
    .searchResultsCardContainerBottom:last-child {
        display: none;
    }
}
@media (max-width: 1000px) {
    .searchResultsTopBar {
        height: 100%;
        margin-top: 1rem;
    }
    .resultsTopBarRightColumn {
        width: 50%;
        order: -1;
        padding: 2rem 0;
    }
    .resultsTopBarLeftColumn {
        width: 45%;
        height: 100%;
        padding-right: 0;
        padding-left: 5%;

        div {
            box-sizing: border-box;
            width: 50%;
            display: flex;
            justify-content: flex-start;
            padding: .625rem;
        }
    }
}
@media (max-width: 750px) {
    .searchResultsCard,
    .extendedCardInfo,
    .cardDetails {
        width: 100%;
    }
}
@media (max-width: 600px) {
    .searchResultsTopBar {
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        height: 100%;
    }
    .resultsTopBarRightColumn {
        width: 100%;
        padding: 1.5rem 0;
    }
    .resultsTopBarLeftColumn {
        width: 100%;
        padding-left: 0;
        padding-bottom: 1rem;

        div {
            width: 30%;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding: .5rem;
        }
    }
    .searchResultsCardContainerBottom {
        width: 100%;
    }
    .searchResultsCardContainerBottom:last-child {
        display: flex;
    }
    .searchResultsTopBar {
        padding: 0 2rem;
    }
    .searchResultsCardContainer {
        padding: 1rem 2rem;
    }
    .resourceCenterFeaturedCards {
        padding: 0 2rem 2rem 2rem;
    }
}
@media (max-width: 500px) {
    .flexParent {
        flex-wrap: wrap;
    }
}
@media (max-width: 400px) {
    .resultsTopBarLeftColumn {
        div {
         width: 50%;
        }
    }
}