@import '/src/util.scss'; 

.subscriptionErrorContainer{
    display: grid;
    grid-template-rows: repeat(3, auto);
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding: 1%;
}
.pageHeader{
    display: flex;
    color: $choices-grey;
    font-size: 4rem;
    font-weight: 300;
    padding-right:3%;
    padding-left: 7%;
}
.xImg{
    display: flex;
    max-width: 100%;
    height: auto;
} 
.heroMessage{
    width:100%;
    font-size: 2rem;
    font-weight: 300;
    padding-left: 6%;
}
.btnContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7%;
    margin-bottom: 10%;

}
.tryAgainBtn{
    background-color:$choices-blue;
    color:$choices-white;   
    border:2px solid $choices-blue;
    margin-bottom: 1.5%;
  } 
  .contactUsBtn{
    display: flex;
    background-color:$choices-white; 
    color:$choices-grey;   
    border:2px solid $choices-grey;
    
  }
  @media (min-width: 800px){
    .topSection{
        display: flex;
    }
    .contactUsBtn, .tryAgainBtn {
        display:flex;
        justify-content: center;
        width: 320px;
        height: 77px;
        font-size:30px;
        padding:16px 32px;
        text-decoration: none;
        text-transform:uppercase;
    }
  }
  @media (max-width: 799px){
    .subscriptionErrorContainer{
        display: grid;    
        grid-template-rows: repeat(5, auto);
        width: 90%;
        height: 95%;
        margin-left: auto;
        margin-right: auto;
    }
    .topSection{
        display: flex;
        flex-direction: column;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
    .xImg{
        grid-row-start: 1;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
    }
    .pageHeader{
        grid-row-start: 2;
        font-size: 3rem;
        font-weight: 500;
        padding-right: 0;
    }
    .heroMessage{
        grid-row-start: 3;
        font-size: 1.8rem;
        width: 95%;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        p{
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .btnContainer{
        grid-row-start: 4;
        margin-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;

    }
    .tryAgainBtn{
        margin-bottom:5%;
    }
    .contactUsBtn, .tryAgainBtn {
        display:flex;
        justify-content: center;
        width: 210px;
        height: 60px;
        font-size:20px;
        padding:16px 32px;
        text-decoration: none;
        text-transform:uppercase;
    }
}