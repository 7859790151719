@import '/src/util.scss';

.btnContainer {
    min-width: 6.5rem;
    max-width: 6.5rem;
    min-height: 2.25rem;
    border: solid 2px $choices-blue;
    background: $choices-white;
    display: flex;
    align-items: center;
}

.btn {
    position: relative;
    background: none;
    border: none;
    height: 100%;
    width: 100%;
    color: $choices-blue;
    font-size: 1rem;
    cursor: pointer;
}

.btnContainer:hover {
    transition: all .15s;
    box-shadow: 0 0 0.1rem 0.1rem $choices-blue-transparent;
}

.btnContainer:active {
    transition: all .2s linear;
    background-color: $choices-blue;

    .btn {
        color: $choices-white;
    }
}


@media (min-width: 1400px) {
    .btnContainer {
        min-width: 7.5rem;
        max-width: 7.5rem;
        height: 2.7rem;
    }

    .btn {
        font-size: 1.2rem;
    }
}