@import '/src/util.scss';

.managePlanPageContainer {
    width: 100%;
    height: 94vh;
    position: relative;
    display: grid;
    grid-template-columns: minmax(220px, 20%) minmax(50%, 400px) 1fr;
    padding: 5rem 1rem 10rem 1rem;
}

.managePlanContentContainer {
    grid-column: 2 / 3;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    animation: slideIn .25s ease-out;
    background: $choices-white;
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(88, 89, 91, 0.376);
}

.managePlanTitle {
    width: 100%;
    margin: 0;
    padding: 0 0 2rem 0;
    color: $choices-blue;
    font-size: 4rem;
    font-weight: 100;
}

.managePlanInfoContainer {
    width: 30%;
}

.purchaseHistoryContainer {
    width: 70%;
}

.purchaseHistoryHeaders {
    width: 100%;
    color: $choices-grey;
    font-size: .875rem;
    padding: .5rem 0;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid $choices-grey;

    span:nth-child(1) {
        width: 50%;
    }

    span:nth-child(2) {
        width: 25%;
    }

    span:nth-child(3) {
        width: 25%;
        text-align: right;
        padding-right: 1rem;
    }
}

.purchaseHistoryText {
    padding: .5rem 0;
    font-size: .875rem;
    display: flex;
    justify-content: space-around;


    span:nth-child(1) {
        width: 50%;
    }

    span:nth-child(2) {
        width: 25%;
    }

    span:nth-child(3) {
        width: 25%;
        text-align: right;
        padding-right: 1rem;
    }
}



.contentBoxContainer {
    width: 100%;
    padding: .5rem .25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.contentBoxHeader {
    width: 100%;
    margin: 0;
    color: $choices-blue;
    font-size: 1rem;
}

.contentBoxText {
    width: 100%;
    margin: 0;
    font-size: .875rem;
    font-weight: 400;
    display: flex;
}

.cancelButtonContainer {
    margin-top: 3rem;
    min-height: 3.25rem;
    background: $choices-white;
    display: flex;
    align-items: center;
}

.cancelButton {
    position: relative;
    background: none;
    border: solid 2px #0076BE;
    height: relative;
    width: relative;
    color: #0076BE;
    font-size: 1.2rem;
    cursor: pointer;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
    padding: 10px;
}

.cancelButtonContainer :hover {
    transition: all 0.15s;
    box-shadow: 0 0 0.1rem 0.1rem rgba(0, 118, 190, 0.25);
}

.cancelButtonContainer:active {
    transition: all .2s linear;
    background-color: $choices-blue;

    .cancelButton {
        color: $choices-white;
    }
}


@media(max-width: 650px) {

    .managePlanContentContainer { 
        flex-direction: column;
       
    }

    .purchaseHistoryContainer {
        width: 100%;
    }

    .managePlanInfoContainer {
        width: 100%;
    }
    
    .cancelButtonContainer {
        max-width: 25%;
    }
}

@media(max-width: 900px) {
    .managePlanPageContainer {
        grid-template-columns: minmax(220px, 20%) minmax(435px, auto);
    }
}

@media(max-width: 750px) {
    .managePlanPageContainer {
        grid-template-columns: auto;
    }

    .managePlanContentContainer {
        grid-column: 1/3;
    }

    .purchaseHistoryContainer {
        .contentBoxHeader {
            padding-top: 2rem;
        }
    }
}

@media(max-width: 450px) {
    .managePlanTitle {
        font-size: 3rem;
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(40rem);
    }

    100% {
        opacity: 1;
    }
}