@import '/src/util.scss';

.stateOfResidenceContainer {
    width: 100%;
    height: 5rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.stateOfResidenceLabel {
    width: 100%;
    height: 30%;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #0076BE;
    font-weight: 600;
    margin-bottom: .25rem;
}

.stateOfResidenceInput {
    width: 100%;
    height: 2.5rem;
    padding-left: 1rem;
    background: $choices-input-fill;
    z-index: 1;
}

.stateOfResidenceInput:focus {
    border: solid $choices-blue 1px;
    outline: none;
}

.stateOfResidenceUL {
    width: 100%;
    margin: 0;
    padding: 0;
    background: $choices-blue;
    position: absolute;
    top: 85%;
    z-index: 1;
    display: flex;
    flex-direction: column;

    cursor: pointer;
}

.stateOfResidenceUL .stateOfResidenceLI {
    padding: .75rem;

}

.stateOfResidenceLI {
    width: 100%;
    list-style: none;
    font-size: .875rem;
    color: $choices-white;
}

.stateOfResidenceLI:hover {
    transition: all .2s linear;
    background: $choices-navy;
    color: $choices-grey-highlight;
}

.stateNotValidMSG {
    width: 100%;
    font-size: small;
    color: $choices-red;
    position: absolute;
    top: 85%;
}

.input_XContainer {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 42%;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.input_X {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
}

.input_X::before,
.input_X::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 2px;
    background: $choices-grey;
    border-radius: 15px;
    transition: transform .2s linear;
}

.input_X::before {
    transform: translateY(-12px);
    transform: rotate(-135deg);
}

.input_X::after {
    transform: translateY(2px);
    transform: rotate(135deg);
}

.input_XContainer:hover .input_X::before,
.input_XContainer:hover .input_X::after {
    background: $choices-red;
    transition: all .2s linear;
}


.selectedState {
    transition: all .2 linear;
    background: white;
    color: $choices-blue;
    border: 1px solid $choices-blue;
}

.modal {
    width: 100%;
    height: 100%;
    padding: 0 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15;
}

.modalContainer {
    min-width: 265px;
    width: 50%;
    max-width: 500px;
    height: 50%;
    padding: 1rem;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 1rem;

    position: relative;
    box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem $choices-grey-transparent;
}

.modalHeaderContainer {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}

.modalHeader {
    margin: 0;
    font-size: 2rem;
    font-weight: 300;
    color: $choices-navy;
}

.modalFormContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.modalForm {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.modalButtonComplete,
.modalButtonIncomplete {
    margin-top: .75rem;
}

.modalButtonIncomplete {
    pointer-events: none;
    *:not(button) {
        border: 1px solid $choices-grey;
    }
    button {
        color: $choices-grey;
    }
}

.logoMark {
    width: 50%;
    overflow: hidden;
    position: absolute;
    right: 0;
    bottom: -4px;
    z-index: 0;
}
@media (min-width: 1300px) {
    .modalContainer {
        padding: 2rem;
    }
}
@media (min-width: 800px) {
    .modalSpan {
        display: none;
    }
}

@media (max-width: 1400px) {
    .stateOfResidenceLabel {
        font-size: 1rem;
    }
}

@media (max-width: 750px) {
    .modal {
        padding: 0;
    }
}