@import '/src/util.scss';

.homeContainer {
    min-height: max-content;
    margin-bottom: 2rem;
    color: $choices-grey;
    transition: opacity 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: slideIn .4s ease-in-out;
    width: 100%;
    height: 100%;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    width: 90vw;
    z-index: 1;
    margin-bottom: 2rem;
}

.featuredOffersContainer {
    margin-bottom: 1vw;
}

.welcomeMessage {
    font-size: max(1.5rem, 2vw);
    max-width: 90vw;
    width: 90vw;
    text-align: left;
    margin-block: max(1rem,2vw) max(1.5rem,3vw);
}

.welcomeMessageUser {
    color: $choices-blue;
}

.title {
    font-size: max(3rem,4vw);
    font-weight: 200;
    margin: 0;
}

.linkToSearchPage {
    padding: 1rem 1rem 1rem 2rem;
    color: $choices-white;
    font-size: max(1.5rem, 2vw);
    font-weight: 200;
    margin-top: min(2rem, 5%);
    max-width: min(90vw,calc(45% + 10rem));
    width: 85%;
}

.brightBlue {
    background-color: $choices-home-bright-blue;
    animation: slideIn .8s ease-in-out, fadeIn .8s ease-in-out forwards;
    opacity: 0;
}

.blue {
    background-color: $choices-home-blue;
    animation: slideIn .8s ease-in-out .2s, fadeIn .8s ease-in-out .2s forwards;
    opacity: 0;
}

.fadedBlue {
    background-color: $choices-home-faded-blue;
    animation: slideIn .8s ease-in-out .4s, fadeIn .8s ease-in-out .4s forwards;
    opacity: 0;
}

.darkBlue {
    background-color: $choices-home-dark-blue;
    animation: slideIn .8s ease-in-out .6s, fadeIn .8s ease-in-out .6s forwards;
    opacity: 0;
}

.hoverContent {
    padding: 0;
    margin: 0;
    font-size: max(1.2rem, 1.2vw);
    color: $choices-white;
    font-style: italic;
}

.linkToSearchPage:hover {
    cursor: pointer;
    transform: scale(102%);
    transition: transform .2s ease-in;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0vw);
    }
}