@import '/src/util.scss';

.contentContainer {
    width: 37.5rem;
    max-width: 88vw;
    margin: 2rem auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-shadow: .1rem .1rem .2rem .1rem $choices-grey-transparent;
    background-color: $choices-white;
}

.subscriptionBody {
    width: 100%;
    padding: 1rem 0;
}

.disable-scrolling {
    overflow: hidden;
  }

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7rem;
    font-size: max(2rem, 1.5vw);
    font-weight: 200;
    margin: 0;
    color: $choices-white;
    text-align: center;
    font-weight: 300;
    background: linear-gradient(90deg, $choices-navy 0%, $choices-blue 100%);
}

.subTitle {
    width: 100%;
    font-size: $font-small;
    font-weight: 300;
    padding: 0 2rem;
    text-align: center;
}


.pricePerYear {
    color: $choices-blue;
    font-size: $font-XXlarge;
    font-weight: 400;
}

.pricePeriod {
    color: $choices-blue;
    font-size: 1.6rem;
}

.priceItem {
    text-align: center;
}

.subscribeBtn {
    background: none;
    border: none;
    color: $choices-blue;
    cursor: pointer;
    font-size: 1rem;
    height: 100%;
    position: relative;
    width: 100%;
}

.btnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
   
}

.btnContainer:last-of-type {
    margin: 1rem 0 3rem 0;
    
}

.btnContainerSubscribe {
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    button {
        background: $choices-blue;
        color: $choices-white;
    }
}

.buttonContainer {
    .btnContainerSubscribe {
        button:active {
            transition: all .2s linear;
            background: $choices-white;
            color: $choices-blue;
        }
    }  
}

.planDetails {
    padding-right: 1.9rem;
    padding-left: 1.9rem;
    font-style: normal;
    font-weight: 300;
    font-size: $font-small;
}

.subscribeDisclousreContainer {
    background: $choices-white;
    padding: 2rem;
}
.subscribeDisclousre {
    text-align: justify;
}

@media (max-width: 480px) {
    .title {
        font-size: max(1.2rem, 2vw);
    }

    .pricePerYear {
        font-size: $font-Xlarge;
    }
}

@media screen and (max-width: 999px) {
    .planBullets {
        padding-left: 1.5rem;
    }
}