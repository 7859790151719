@import '/src/util.scss';

.form {
    height: min-content;
    background-color: $choices-white;
    z-index: 3;
    width: 95%;
    max-width: 30rem;
    margin-top: 4rem;
    animation: fadeIn 0.5s ease-in-out;
}

.btnContainer {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    div {
        min-width: max-content;
        button {
            font-size: max(1.2rem, 1.2vw);
        }
    }
}

@mixin responseMessage {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: max(1rem, 1vw);
}

.hidden {
    visibility: hidden;
}

.successMessage {
    visibility: visible;
    color: $choices-green;
    @include responseMessage();
}

.errorMessage {
    visibility: visible;
    color: $choices-red;
    @include responseMessage();
}

.animate {
    animation: fadeIn 0.5s ease-in-out;
}

@media (min-width: 600px) {
    .form {
        border: solid $choices-grey-transparent 1px;
        margin-top: 12rem;
        padding: 2rem;
    }
    .btnContainer {
        flex-direction: row;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}