@import '/src/util.scss';

.header {
    z-index: 15;
    max-width: 100%;
    box-sizing: border-box;
    position: relative;
}

.noScroll {
    overflow: hidden;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    flex-wrap: nowrap;
    font-size: 1.25rem;
    background-color: $choices-white;
}

.navItems {
    list-style-type: none;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.navHidden {
    display: none;
    list-style-type: none;
    width: 100%;
    justify-content: flex-end;
}

.navLink {
    padding-right: 2.5%;
    text-align: center;

    .navLink:last-child {
        padding-right: 0;
    }

    a {
        text-decoration: none !important;
        color: $choices-grey;
    }
}

.mobileToggle {
    display:flex;
    align-items: center;
    padding-right: 1rem;
}

.brand {
    margin-right: auto;
    display: flex;
    width: 7rem;
    height: auto;
    padding: 0.5rem;
    animation: fadeIn .3s ease-in-out;

    a {
        width: 100%;
        height: auto;

        img {
            max-height: 3rem;
        }
    }
}

@media (min-width: 900px) and (max-width: 1100px) {
    .navLink {
        font-size: 1rem;
    }
}

@media (max-width: 899px) {
    ul {
        padding: 0;        
    }
    .nav {
        max-width: 100%;
    }

    .brand {
        width: 80%;
        a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .mobileToggle {
        width: 20%;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        padding: 1rem;
        position: relative;
    }

    .toggleNavBtn {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        height: 4px;
        background: $choices-grey;
        border-radius: 15px;
    }

    .toggleNavBtn::before,
    .toggleNavBtn::after {
        content: '';
        position: absolute;
        width: 40px;
        height: 4px;
        background: $choices-grey;
        border-radius: 15px;
        transition: transform .2s linear;
    }

    .toggleNavBtn::before {
        transform: translateY(-12px);
    }

    .toggleNavBtn::after {
        transform: translateY(12px);
    }

    .mobileToggle .toggledNavBtn {
        background: transparent;
        position: absolute;
        width: 40px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .5s ease-in-out;
    }

    .mobileToggle .toggledNavBtn::before,
    .mobileToggle .toggledNavBtn::after {
        content: '';
        position: absolute;
        width: 40px;
        height: 4px;
        background: $choices-grey;
        transition: all .5s ease-in-out;
    }

    .toggledNavBtn::before {
        transform: rotate(135deg);
    }

    .toggledNavBtn::after {
        transform: rotate(-135deg);
    }
 
    .navItems {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        position: fixed;
        height: 100%;        
        top: 2.75rem;
        right: 0;
        background-color: $choices-navy;
        color: $choices-white;
        animation: slideDown .25s ease-out;
    }
    .navLink:not(div):hover {
        width: 100%;
        background: $choices-navy-dark;
    }
    .navLink:not(div) a:hover {
       color: $choices-grey-highlight;
    }

    .navLink {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 50px;        
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        a {
            color: $choices-white;
            padding-right: 1.25rem;
        }
    }
    .activeLink {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: $choices-blue-transparent;
        .linkText {
            position: absolute;
            right: 1rem;
            bottom: .75rem;
        }
    }
}

@media (min-width: 900px) {
    .mobileToggle {
        display: none;
    }
    .navHidden {
        display: flex;
    }
    .brand {
        width: 12rem;
        height: auto;
        padding: 1rem;
    }
    .navLink:hover {
        transform: scale(1.025);
        transition: transform .2s linear;
        a {
            color: $choices-blue;
        }
    }
    .activeLink {
        color: $choices-blue !important;
    }
}

@keyframes slideDown {
    0% {
        top: 0;
        opacity: .6;
    }
    100% {
        top: .75;
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        filter: blur(6px);
    }
    100% {
        opacity: 1;
    }
}