@import '/src/util.scss';

.pageContainer {
    min-height: 65vh;
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

@media (min-width: 700px) {
    .pageContainer {
        justify-content: flex-start;
    }
}
