@import '/src/util.scss';

.offerBtn {
    color: $choices-grey;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
    span {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: max(1rem, 1vw);
    }
}