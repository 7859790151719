@import '/src/util.scss';

.cardContainer {
    border: solid $choices-grey-transparent 1px;
    display: flex;
    padding: 1rem;
    background-color: $choices-white;
    animation: fadeIn 0.5s ease-in-out;
}

.altCardContainer {
    display: flex;
    justify-content: center;
    background-color: $choices-white;
    
}

.imgContainer {
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5%;
    img {
        max-height: 8rem;
        width: 100%;
        object-fit: contain;
    }
}

.altImgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-height: 10rem;
        min-width: 5rem;
        margin-top: 0.5rem;
        width: 100%;
        object-fit: contain;
    }
}

.text {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: max(1rem,20%);
    color: $choices-grey;
    p {
        margin: 0;
    }
}

.altText {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: max(1rem,20%);
    color: $choices-grey;
    p {
        margin: 0;
    }
}

.title {
    font-size: max(1.2rem, 1.2vw);
}

.altTitle {
    font-size: max(1.2rem, 1vw);
}

.action {
    color: $choices-blue;
    width: 100%;
    font-size: max(1.2rem, 1.2vw);
}

.altAction {
    color: $choices-blue;
    width: 100%;
    font-size: max(1.2rem, 1vw);
}

.button {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.altButton {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.altContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 2rem;
}

@media (min-width: 600px) {
    .container {
        flex-direction: row;
        width: 100%;
    }
    .text {
        padding: 1rem;
    }
    .button {
        margin: 0 1rem;
        display: flex;
        justify-content: center;
    }
    .title {
        font-size: max(1.5rem, 1.5vw);
    }
}

@media (min-width: 850px) {
    .action {
        font-size: max(1.5rem, 1.2vw);
    }
    .title {
        font-size: max(2rem, 1.5vw);
    }
    .cardContainer {
        padding: min(1.5rem,1.2vw) min(2rem,1.5vw);
    }
    .button {
        div {
            min-width: max-content;
            padding: 0.25rem 0.5rem;
            button {
                font-size: max(1.5rem, 1.2vw);
            }
        }
    }
}

@media (min-width: 1600px) {
    .text {
        padding: 0;
    }
}

@keyframes fadeIn {
    0% {
        transform: translateX(20vh);
        opacity: 0;
    }
    100% {
        transform: translateX(0vh);
        opacity: 1;
    }
}