@import '/src/util.scss';

.contentContainer {
    display: flex;
    flex-direction: column;
    z-index: 1;
    margin-bottom: 2rem;
    width: 37.5rem;
    left:19.5rem;
    top:9.8rem;
    border: solid .1rem $choices-grey-light;
    margin-left: auto;
    margin-right: auto; 
    background-color: $choices-white;   
    max-width: 88vw;
    box-shadow: .1rem .1rem .2rem .1rem $choices-grey-transparent;  
    background-color: $choices-white;
    z-index: 1;
    padding:3rem;
}

.brightBlue {
    background: linear-gradient(90deg, $choices-navy 0%, $choices-blue 100%);
}

.subTitle{
    color:choices-grey;
    font-size: $font-small;
    font-weight: 300;
    padding-right:1.9rem;
    padding-left:1.9rem;
}

.btnContainer {      
    z-index: 3;  
    margin: 0 auto; 
    div {
        margin-top: 1rem; 
        min-width: max-content;
        padding: 0.25rem .5rem;
        background-color: $choices-white;
        height: 2.7rem;
        max-width: 10rem;
        min-width: 10rem;
        button {
            white-space: nowrap;            
        }        
    }
    height: 2.7rem;
    max-width: 10rem;
    min-width: 10rem;    
}

.btnContainerLink {
    @extend .btnContainer;
    div {        
        background-color: $choices-blue;
        button {          
            color:$choices-white           
        }      
    }
}

@media (max-width: 480px){
      .contentContainer{
        padding: 1rem;
    }
}