@import '/src/util.scss';

.container {
    position: fixed;
    background: transparent;
    display: flex;
    bottom: 12rem;
    left: 0;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    z-index: 3;
}

.message {
    position: relative;
    top: 0;
    left: 0;
    border-radius: 12px;
    padding: 1.2rem 2rem;
    font-size: 1.2rem;
    margin-block: 1rem;
    max-width: min(95%,50rem);
    filter: drop-shadow(4px 4px 2px $choices-grey-transparent);
}

.close {
    position: absolute;
    top: 0;
    right: 0.5rem;
    cursor: pointer;
    background: none;
    border: none;
    color: $choices-white;
    font-size: 1.2rem;
    &:hover {
        transition: 0.2s;
        transform: scale(125%);
    }
}

.success {
    background-color: $choices-green;
    color: $choices-white;
}

.error {
    background-color: $choices-red;
    color: $choices-white;
}

.fadeInUp {
    animation: fadeInUp 0.4s ease-in-out;
}

.fadeOutDown {
    animation: fadeOutDown 0.6s ease-in-out forwards;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        top: 12rem;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        top: 0
    }
    100% {
        opacity: 0;
        top: 12rem;
        height: 0;
        padding: 0;
        margin: 0;
    }
}