@import '/src/util.scss';

.styledLink {
    max-width: 100%;
    display: flex;
}
.linkStyle {
    padding: .5rem 1.25rem;
    text-decoration: none;
    text-align: center;
}

.styledLinkSmall {
    font-size: .75rem;
}
.styledLinkLarge { 
    font-size: 1.25rem;
}

.styledLinkNavy {
    border: 1px solid $choices-navy;
    color: $choices-navy;
}
.styledLinkNavy:hover {
    transition: all .15s ease-out;
    box-shadow: 0 0 2px 1px $choices-navy;
}
.styledLinkNavy:active {
    background-color: $choices-navy;
    color: $choices-white;
    transition: all .15s ease-out;
}

.styledLinkWhite {
    border: 1px solid $choices-white;
    color: $choices-white;
}
.styledLinkWhite:hover {
    transition: all .15s ease-out;
    box-shadow: 0 0 2px 1px $choices-white;
}
.styledLinkWhite:active {
    transition: all .15s ease-out;
    background-color: $choices-white;
    color: $choices-blue;
}