@import '/src/util.scss';
.footer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    position: relative;
    color: $choices-white;
    background-color: $choices-navy;
}

.bottomBanner {
    background-color: $choices-navy-dark;
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 200;
}

.bottomContainer {
    display: flex;
    width: min(70rem, 90%);
    flex-direction: column;
    text-align: center;
    padding: 0.5rem 0;
    margin-bottom: 2rem;
    p {
        margin-bottom: 0.5rem;
    }
    a, a:visited {
        margin-top: 1rem;
        color: $choices-white;
    }
}

.navLink {
    text-decoration: underline;
    font-weight: 200;
    font-size: max(1.1rem, 1.1vw);
    color: $choices-white;
    line-height: max(1.5rem, 2vw);
    white-space: nowrap;
}

.navLink:hover {
    font-weight: 400;
    transition: all .1s linear;
}

.optionsContainer {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 2rem;
    justify-content: space-between;
    padding: 1rem;
    margin-left: 5%;
    width: 100%;
}

.option {
    display: flex;
    flex-flow: column wrap;
    padding: 0 0.5rem;
    width: 33%;
}

.optionItems {
    display: flex;
    flex-flow: column wrap;
}

.optionTitle {
    text-transform: uppercase;
    font-size: max(1.2rem, 1.2vw);
    font-weight: 600;
    padding-bottom: min(1rem,5%);
}

.rightOption {
    flex: 0 1 30%;
    align-items: flex-end;
    padding-right: 1rem;
}

.middleOption {
    flex: 2 0 40%;
    .optionItems {
        flex-flow: column wrap;
    }
}

.leftOption {
    flex: 0 1 30%;
}

@media (min-width: 500px) {
    .optionsContainer {
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: 0;
        gap: 0rem;
    }
}

@media (min-width: 700px) {
    .optionsContainer {
        width: max(45rem,75%);
    }
}

@media (min-width: 1200px) {
    .optionsContainer {
        width: 100%;
    }
    .option {
        max-width: 20rem;
    }
}

