@import '/src/util.scss';


.cardContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    border: solid $choices-grey-transparent 1px;
    background-color: $choices-white;
    min-height: min(21rem,20%);
    height: 100%;
    min-width: fit-content;
}

.cardHeader {
    background-color: $choices-navy;
    border: solid $choices-grey-transparent 1px;
    color: $choices-white;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.25rem;
    padding: 0.25rem;
    min-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardBody {
    padding: 0 1rem 0.25rem 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.imageContainer {
    width: 100%;
    padding-top: 0.75rem;
    img {
        object-fit: cover;
        max-width: 100%;
    }

    display: flex;
    justify-content: center;
}

.discountPercentage {
    text-align: center;
    width: 100%;
    color: $choices-blue;
    font-size: 2.5rem;
    margin-top: -0.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.discountDisclaimer {
    text-align: center;
    width: 100%;
    color: $choices-blue;
    font-size: 1.5rem;
}

.lastViewed {
    color: $choices-blue;
    text-align: center;
    width: 100%;
    font-size: 1rem;
    white-space: nowrap;
}

.btnContainer {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        white-space: nowrap;
    }
}

@media (min-width:915px) {
    .cardHeader {
        padding: 0.5rem;
    }
    .btnContainer {
        padding-bottom: 1rem;
    }
    .discountPercentage {
        font-size: 3.5rem;
    }
    .lastViewed {
        font-size: 1.1rem;
    }
    .cardBody {
        padding: 0 1.5rem
    }
}