@import '/src/util.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    min-width: 50vw;
    padding-bottom: 2rem;
}

.heading {
    color: $choices-blue;
    font-size: max(2rem, 2.5vw);
    font-weight: 200;
    margin-block: 2rem 4rem;
    max-width: min(90%,60rem);
}

.imgContainer {
    width: 100%;
    height: 100%;
    max-height: max(20rem, 40vh);
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}