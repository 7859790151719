@import '/src/util.scss';

.articleCardContainer {
    width: 100%;
    width: 100%;
    height: 250px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 0;
}
.bgContainer {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
}
.articleCard {
    width: 100%;
    height: 100%;
    color: $choices-white;
    background: $choices-grey-dark-transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.25rem;
}
.articleCardContainer:hover {
    transition: all .15s ease-out;
    box-shadow: 0 0 2px 1px $choices-navy;
}
.cardReadTime {
    margin: 0;
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
}
.cardTitle {
    width: 100%;
    overflow: hidden;
    font-size: 2rem;
}
@media (max-width: 600px) {
    .cardTitle {
        font-size: 1.75rem;
    }
}