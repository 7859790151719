@import '/src/util.scss';

.featuredOffers {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    min-width: 100vw;
    background-color: $choices-blue;
    z-index: 2;
    min-height: 25rem;
}

.featuredOffersHero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    min-width: 100vw;
    background-color: $choices-navy;
    z-index: 2;
    padding: 1rem 0;
    gap: max(2rem, 4vw);
}

.heroText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: min-content;
    gap: max(2rem,2vw);
    height: fit-content;
    a {
        text-decoration: none;
        color: $choices-white;
        border: solid $choices-white 2px;
        padding: .75rem 1rem;
    }
    a:hover {
        background-color: $choices-white;
        color: $choices-navy;
        transition: .4s;
    }
}

.carouselControl {
    display: flex;
    width: 90vw;
    min-height: 25vh;
    justify-content: center;
    margin-bottom: 2rem;
}

@mixin scaleDown() {
    transform: scale(.8);
} 

.carouselControlHero {
    display: flex;
    width: fit-content;
    justify-content: center;
}

.carousel {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.carouselHero {
    display: flex;
    gap: 1rem;
    @include scaleDown();
}

.cardContainer {
    display: flex;
    width: 100%;
    border: solid $choices-grey-transparent 1px;
    margin: 0 1rem;
}

.title {
    color: $choices-white;
    white-space: nowrap;
    font-size: min(4rem,12vw);
    font-weight: 200;
    margin: 1rem;
}

.titleHero {
    color: $choices-white;
    white-space: nowrap;
    font-size: max(3rem,4vw);
    font-weight: 200;
    margin: 0rem;
}

.carouselBtn {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    img {
        padding: 1.5rem;
        width: max(2rem,2vw);
        object-fit: contain;
    }
}

.carouselBtnHero {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    img {
        width: max(1rem,1vw);
        object-fit: contain;
    }
}

.animateLeft {
    animation: slideLeft 0.3s ease-in-out;
}

.animateRight {
    animation: slideRight 0.3s ease-in-out;
}

.animateHeroLeft {
    animation: slideHeroLeft 0.3s ease-in-out;
}

.animateHeroRight {
    animation: slideHeroRight 0.3s ease-in-out;
}

@keyframes slideHeroLeft {
    0% {
        opacity: 0;
        transform: translateX(-10vh);
        @include scaleDown();

    }
    100% {
        opacity: 1;
        transform: translateX(0vh);
        @include scaleDown();
    }
}

@keyframes slideHeroRight {
    0% {
        opacity: 0;
        transform: translateX(10vh);
        @include scaleDown();
    }
    100% {
        opacity: 1;
        transform: translateX(0vh);
        @include scaleDown();
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-10vh);

    }
    100% {
        opacity: 1;
        transform: translateX(0vh);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(10vh);
    }
    100% {
        opacity: 1;
        transform: translateX(0vh);
    }
}

@media (min-width: 900px) {
    .heroText {
        margin-top: -1rem;
    } 
    .featuredOffersHero {
        padding: 0;
    }
}

@media (min-width: 1000px) {
    .carouselControlHero {
        &>button:nth-child(1) {
            margin-right: clamp(-3rem, -3vw, -1rem);
        }
        &>button:nth-last-child(1) {
            margin-left: clamp(-3rem, -3vw, -1rem);
        }
    }
}