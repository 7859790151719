@import '/src/util.scss';

.columnContainer {
    max-width: 220px;
    height: 100%;
    display: flex;
    flex-basis: 20%;
    flex-direction: column;
    align-items: center;
    flex-shrink: 1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: $choices-white;
    border-right: 4px solid $choices-grey-highlight;
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(88, 89, 91, 0.376);
}

.columnContainerOpen {
    animation: openTab .25s ease-out;
}

.columnContainerClosed {
    animation: closeTab .25s ease-out forwards;
}

.columnContainer * {
    width: 100%;
}

.columnOverflow {
    width: 217px;
    height: 100%;
    padding: 1.5rem;
    position: relative;
    left: 0%;
}

.columnTabContainer {
    width: 40px;
    height: 80px;
    padding: 5px;
    background: $choices-blue;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(88, 89, 91, 0.376);
    cursor: pointer;
}

.columnTab {
    * {
        color: $choices-white;
        display: flex;
    }

    svg {
        height: 100%;
    }
}

.columnTabContainer:hover {
    transition: all .2s linear;
    background: $choices-navy;
}

.columnHeaderContainer {
    height: 100px;
}

.columnHeader {
    @include choices-title($choices-navy, $font-med, 400);
}

.columnImgContainer {
    padding: 1rem 4rem 1rem 0;

    img {
        min-width: 160px;
    }
}

.columnNavContainer {
    display: flex;
    justify-content: space-between;
}

.columnNav {
    ul {
        padding: 0;
    }

    li {
        list-style: none;
        padding: .5rem 0;
    }
}

.columnNavLink {
    * {
        color: $choices-navy;
        font-size: 1rem;
        text-decoration: none;
    }
}

.columnNavLink:hover {
    * {
        transition: all .2s linear;
        color: $choices-blue;
    }
}

.activeColumnLink {
    color: $choices-blue;
    font-weight: 700;
}

.columnAccountName {
    @include choices-title($choices-navy, $font-small, 400);
}

@media (max-width: 1000px) {
    .columnHeader {
        font-size: 1.25rem;
    }

    .columnAccountName {
        font-size: 1rem;
    }
}

@keyframes openTab {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes closeTab {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}