@import '/src/util.scss';

.articleLandingContainer {
    width: 60%;
    height: 400px;
    border-radius: 15px;
}
.articleLandingImage {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.articleLandingBottomBanner {
    width: 100%;
    height: 100px;
    background: rgba(255, 255, 255, 0.85);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
}
.articleLandingLeft,
.articleLandingCenter,
.articleLandingRight {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $choices-navy;
}
.articleLandingLeft {
    width: 45%;
}
.articleLandingHeader {
    width: 100%;
    text-align: center;
    @include choices-title($choices-navy, $font-small, 400);
    padding: .5rem;
}
.articleLandingCenter {
    width: 30%;
}
.articleLandingRight {
    width: 25%;
}
.articleLandingReadTime {
    font-size: 1.25rem;
    text-align: center;
    font-weight: 400;
    font-style: italic;
}

@media (min-width: 1300px) {
    .articleLandingImage {
       background-position: center top;
       background-size: cover;
    }
}
@media (max-width: 750px) {
    .articleLandingContainer {
        height: 300px;
        width: 100%;
        border-radius: 0;
        margin-bottom: 2.5rem;
    }
    .articleLandingBottomBanner {
        border-radius: 0;
        justify-content: space-around;
    }
    .articleLandingHeader {
        padding: .5rem;
    }
    .articleLandingRight {
        display: none;
    }
    .articleLandingLeft {
        width: 70%;
    }
    .articleLandingCenter {
        width: 30%;
    }
}


