@import '/src/util.scss';

.imgContainer {
    width: 100%;
    height: 100%;
}

.stockImg {
    width: 100%;
    height: 100%;
    filter: saturate(1.1) brightness(1.1);
    animation: fadeIn 1.25s ease-out;
    object-fit: cover;
}

.menuContainer {
    position: relative;
    top: -2.5rem;
    float:right;
    margin-right: 5%;
    height: 1.5rem;
    width: fit-content;
}

.menuBtn {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
}

.menuBtn:hover {
    .outerCircle {
        filter: drop-shadow(2px 0 10px #fff);
        opacity:1;
        cursor:pointer;
        position: relative;
        z-index: 0;
        svg {
            path {
                opacity: 1;
            }
        }
    }
    .hidden  {
        path {
            opacity: 1;
            transition: opacity .2s ease-in;
        }
    }
}

.outerCircle {
    height: 1.5rem;
    opacity: 0.75;
    padding-left: 0.5rem;
}

.outerCircle:hover {
    filter: drop-shadow(2px 0 10px #fff);
    opacity:1;
    cursor:pointer;
    position: relative;
    z-index: 0;
}

.hidden  {
    path {
        opacity: 0;
        cursor:pointer;
        transition: opacity 1s;
    }
}

.innerCircle {
    position: absolute;
    opacity: 0.75;
    height: 1.1rem;
    width: 1rem;
    top: 0.2rem;
    z-index: 1;
    cursor:pointer;
    path {
        filter:drop-shadow(0px 0px 1px $choices-white);
        cursor: pointer;
    }
}

.position0 {
    left: .75rem;
}

.position1 {
    left: 2.75rem;
}

.position2 {
    left: 4.75rem;
}

.position3 {
    left: 6.75rem;
}

.position4 {
    left: 8.75rem;
}

@keyframes fadeIn {
    0% {
        filter: brightness(0);
    }
    100% {
        filter: brightness(1.1);
    }
}



