@import '/src/util.scss';

.loadingContainer {
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    width:100%;
    overflow-y: hidden;
    svg {
        max-height: 50vh;
        min-width: 20rem;
        max-width: 50vw;
        animation: flash .9s ease-in-out infinite alternate;
    }
}

.fitToFullScreen {
    z-index: 10;
    position: fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
}

.fitToParent {
    position: fixed;
    z-index: 2;
    top: 5rem;
    left: 2.5%;
    background: none;
}

.hidden {
    display: none;
}

@media (min-width: 915px) and (max-width: 1300px )
{
    .fitToParent {
        left: 15%;
    }
}


@keyframes flash {
    0% {
        transform: scale(.7);
        opacity: .75;
    }
    100% {
        transform: scale(1);
        opacity: .25;

    }
}

.whiteBackground {
    background-color: white;
}