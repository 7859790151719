.practitionerContainer {
  margin-bottom: 1rem;
  width: 95vw;
  display: grid;
  grid-template-columns: 20% 63.5%;
  column-gap: 5%;
  height: 41vh;

  .practitionerHeader {
    margin-bottom: 0.5rem;
    text-align: none;
  }

  .productGrid {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    animation: fadeIn ease-in-out .5s;
  }

  .cardResult {
    width: 10vw;
    height: 20vh;
    margin-bottom: 1rem;
    animation: fadeIn ease-in-out .5s;
  }

  .ldn {
    line-height: 20rem;
    font-size: 1.35rem;
    display: flex;
    justify-content: center;
    width: 60vw;
  }


  @media (max-width: 767px) {
    grid-template-columns: 100%;
    margin-bottom: 3.5rem;

    .practitionerHeader {
      width: 95vw;
      text-align: center;
    }

    .cardResult {
      width: 75vw;
      height: 35vh;
    }

    .productGrid {
      overflow: auto;
      grid-template-columns: 80%;
      justify-content: center;
      height: 36.5vh;
    }
  }


  @media (min-width: 768px) {
    grid-template-columns: 100%;
    padding-bottom: 10vh;
    height: 45vh;

    .practitionerHeader {
      width: 95vw;
    }

    .cardResult {
      width: 35vw;
      height: 30vh;
    }

    .productGrid {
      grid-template-columns: 20% 20% 20% 20%;
      column-gap: 20%;
      overflow: auto;
      white-space: nowrap;
      justify-content: start;
      height: 32vh;
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: 20% 78%;
    width: 80vw;
    column-gap: 10%;

    .cardResult {
      width: 18vw;
      height: 33vh;
    }

    .productGrid {
      grid-template-columns: 22% 22% 22% 22%;
      column-gap: 8%;
      overflow: auto;
      white-space: nowrap;
      justify-content: start;
      height: 43vh;
    }
  }

  @media (min-width: 1201px) {
    grid-template-columns: 25% 75%;
    width: 95vw;
    column-gap: 5%;

    .cardResult {
      margin-top: 5%;
      width: 20vw;
      height: 35vh;
    }

    .productGrid {
      grid-template-columns: 22% 22% 22% 22%;
      column-gap: 10%;
      overflow: auto;
      white-space: nowrap;
      justify-content: start;
      height: 40vh;
    }
  }

  @media (min-width: 1400px) {
    grid-template-columns: 22% 70%;
    width: 90vw;
    column-gap: 5%;

    .cardResult {
      margin-top: 5%;
      width: 20vw;
      height: 35vh;
    }

    .productGrid {
      grid-template-columns: 22% 22% 22% 22%;
      column-gap: 12%;
      overflow: auto;
      white-space: nowrap;
      justify-content: start;
      height: 40vh;
    }
  }

  @keyframes fadeIn {
    0% {
      transform: translateX(8vw);
      opacity: 0;
    }

    100% {
      transform: translateX(0vw);
      opacity: 1;

    }
  }

}