@import '/src/util.scss';

.topContentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    .imageContainer {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    p {
        width: 70%;
        font-size: $font-med;
        font-weight: 100;
    }
}


.detailsContainer {
    margin: 2rem auto;
    width: 50rem;
    max-width: 80vw;
    padding: 2rem;
    background-color: $choices-white;
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(88, 89, 91, 0.3764705882);

}

.successMessage {
    font-size: $font-med;
    font-weight: 100;
    margin-left: 2rem;
}

.twoSquareAccessContainer {
    display: grid;
    grid-template-columns: 40% 60%;
}

.accessDetailsTopLeft {    
    font-weight: 300;
    font-size: $font-med;

    .accessTitle {
        color: $choices-blue;
        font-size: $font-med-small;
    }

    .accessBullets {
        color: $choices-grey;
        font-size: $font-small;
    }
}


.purchaseDetails th {
    border-bottom: solid 0.1rem $choices-input-border;
    border-collapse: collapse;
    text-align: left;
    color: $choices-grey;
    font-weight: 100;
}

.purcahseCost {
    text-align: right !important;
}

table {
    width: 100%;
    border-collapse: collapse;
}

.purchaseDetailsTitle {
    color: $choices-blue;
    padding-top: 1.2rem;
}

.accessDetailsContainerTopRight {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    max-width: 100%;
    margin-left: 5rem;
}

.btnGotoChoices {
    @extend .btnContainer;

    div {
        button {
            color: $choices-white
        }
    }
}

.btnContainer {
    display: flex;
    justify-content: center;
    margin: 1rem 0 2rem 0;

    div {
        margin-top: 2rem;
        min-width: max-content;
        padding: 0.25rem 0.5rem;
        background-color: $choices-blue;
    }
}

@media screen and (max-width: 999px) {
    .topContentContainer {
        flex-wrap: wrap;
        justify-content: center;

        p {
            text-align: center;
            padding: 0;
            width: 100%;
        }
    }

    .twoSquareAccessContainer {
        grid-template-columns: auto;
    }

    .twoSquareContainer {
        grid-template-rows: auto;
        grid-template-columns: auto;
    }

    .accessDetailsContainerTopRight {
        margin-left: 0;
    }

    .imgContainer{   
        width: 100%;
        min-width: 6.25rem;
        max-width: 6.25rem;  
      
    }  

    .accessBullets{         
        padding-left:1.25rem; 
    }
}