@import '/src/util.scss';

.form {
    width: 100%;
    padding: 1rem;
    max-width: 30rem;
    min-width: 23rem;
}
.formInputs {
    width: 90%; 
}
.formItem {
    display: flex;
    justify-content: space-between;
}
.emailItem {
    flex-direction: column;
}
.inputContainer {
    max-width: 48%;
    min-width: 48%;

    input[type="password"] {
        text-indent: 1.5rem;
    }
}
.formMenu {
    margin: 0;
    padding: 0;
    display: flex;
    margin-block: 2rem 1rem;
    gap: 1rem;
}
.formBtns {
    display: flex;
    flex-direction: column;
    section {
        margin-top: 0.25rem;
        align-items: flex-start;
        input {
            margin-top: 0.5rem;
        }
        label {
            font-size: medium;
        }
        p {
            display: none;
        }
    }
    input {
        margin-right: 0.5rem;
    }
}
.linkDisclaimer {
    margin-top: 0.5rem;

    a {
        color: $choices-grey;
    }
}
.inputIcon {
    position: absolute;
    margin-left: 0.75rem;
    margin-top: -1.7rem;
    transition: fill .2s ease-out;
}
.positive {
    fill: $choices-green;
}
.negative {
    fill: $choices-red;
}
.disclaimer {
    width: 100%;
    color: $choices-grey;
    margin-top: 2rem;

    a {
        color: $choices-grey;
    }
}
.inputError {
    border-color: $choices-red;
    box-shadow: 0 0 .5px .5px rgba(255, 0, 0, 0.25);
}
.inputError:focus {
    border-color: $choices-red;
    box-shadow: 0 0 .5px .5px rgba(255, 0, 0, 0.25);
}
.errorBanner {
    display: flex;
    position: fixed;
    top: 4rem;
    left: 0;
    min-width: 100vw;
    z-index: 3;
    background-color: $choices-navy;
    color: $choices-white;
    animation: fadeIn .25s ease-in-out, slideDown .2s ease-in-out;
}
.errorMessage {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 60rem;
    margin-left: 5%;
    height: min-content;

    ul {
        margin: -0.5rem 0 1rem 0;
        font-weight: 200;
    }
}
.hidden {
    display: none;
}
.closeError {
    position: absolute;
    border: none;
    background: none;
    color: $choices-white;
    right: max(4.5rem, 5%);
    top: 0.5rem;
    font-size: 2rem;
    cursor: pointer;
}
.closeError:hover {
    transform: scale(115%);
}
label[for=communicationPreference] {
    font-size: 1rem;
}
.preferenceContainer>section {
    height: fit-content;
    width: 100%;
    padding-top: 0.5rem;
}
.preferenceContainer {
    height: fit-content;
    padding-right: 1rem;
}
.subTitle {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
    color: $choices-blue;
}
.errMsg {
    color: $choices-red;
    font-size: small;
}
.stateOfResidenceContainer {
    width: 100%;
    height: 5rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
}
.stateOfResidenceLabel {
    width: 100%;
    height: 30%;
    font-size: 1rem;
    text-transform: uppercase;
    color: #0076BE;
    font-weight: 600;
    margin-bottom: .25rem;
}
.stateOfResidenceInput {
    width: 100%;
    height: 2.5rem;
    padding-left: 1rem;
    background: $choices-input-fill;
}
.stateOfResidenceInput:focus {
    border: solid $choices-blue 1px;
    outline: none;
}
.stateOfResidenceUL {
    width: 100%;
    margin: 0;
    padding: 0;
    background: $choices-blue;
    position: absolute;
    top: 85%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.stateOfResidenceUL .stateOfResidenceLI {
    padding: .75rem;
}
.stateOfResidenceLI {
    width: 100%;
    list-style: none;
    font-size: .875rem;
    color: $choices-white;
}
.stateOfResidenceLI:hover {
    transition: all .2s linear;
    background: $choices-navy;
    color: $choices-grey-highlight;
}
.stateNotValidMSG {
    width: 100%;
    font-size: small;
    color: $choices-red;
    position: absolute;
    top: 85%;
}
.input_XContainer {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 42%;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.input_X {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
}
.input_X::before,
.input_X::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 2px;
    background: $choices-grey;
    border-radius: 15px;
    transition: transform .2s linear;
}
.input_X::before {
    transform: translateY(-12px);
    transform: rotate(-135deg);
}
.input_X::after {
    transform: translateY(2px);
    transform: rotate(135deg);
}
.input_XContainer:hover .input_X::before,
.input_XContainer:hover .input_X::after {
    background: $choices-red;
    transition: all .2s linear;
}
.selectedState {
    transition: all .2 linear;
    background: white;
    color: $choices-blue;
    border: 1px solid $choices-blue;
}

@media (min-width: 600px) {
    .errorBanner {
        top: 7.5rem;
    }
}

@media (min-width: 1400px) {
    .form {
        padding: 1rem 2rem;
    }
    .subTitle {
        font-size: 1.2rem;
        white-space: nowrap;
    }
    .stateOfResidenceLabel {
        font-size: 1.2rem;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-50vh);
    }
    100% {
        transform: translateY(0vh);

    }
}