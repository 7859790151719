@import '/src/util.scss';

.searchInputContainer {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.searchForm {
    width: 100%;
    height: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.searchInput {
    width: 100%;
    height: 36px;
    padding-left: 30px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid $choices-navy-dark;
    font-size: 1rem;
}
.searchInput:focus {
    outline: none;
}
.searchBtn {
    width: 5rem;
    height: 36px;
    border: none;
    background-color: $choices-navy;
    color: $choices-white;
    font-size: 1rem;
    padding: 0rem 1.25rem;
    margin-left: -2px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.searchBtn:hover {
    background: $choices-navy-dark;
    color: $choices-grey-highlight;
}
.inputSearchIcon {
    margin-left: 6px;
    width: 1.25rem;
    color: $choices-navy-dark;
    position: absolute;
    left: 0;
    padding-left: 0;
}
@media (max-width: 900px) {
    .searchBtn {
        padding: 0.125rem .25rem;
    }
}