@import '/src/util.scss';

.recentActivityPageContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: minmax(220px, 20%) minmax(50%, 900px) 1fr;
    grid-template-rows: 1fr;
    padding: 5rem 1rem 10rem 1rem;
    position: relative;
}

.recentActivityContentContainer {
    grid-column: 2 / 3;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    animation: slideIn .25s ease-out;
    background: $choices-white;
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(88, 89, 91, 0.376);
}
.recentActivitytitle {
    width: 100%;
    margin: 0;
    padding: 0 0 2rem 0;
    color: $choices-blue;
    font-size: 4rem;
    font-weight: 100;
}

.activityResults {
    padding-block: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: max(1rem, 1.2vw);
    margin-block: 1rem;
    z-index: 1;
    min-height: 50vh;
}

.pagination {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.noActivity {
    font-size: max(1.5rem, 1.5vw);
}

@media(max-width: 750px) {
    .recentActivityPageContainer {
        grid-template-columns: auto;
    }
}

@media(max-width: 450px) {
    .recentActivitytitle {
        font-size: 3rem;
    }
}

.pagination {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.noActivity {
    font-size: max(1.5rem, 1.5vw);
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(40rem);
    }

    100% {
        opacity: 1;
    }
}