@import '/src/util.scss';

.cardContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border: solid $choices-grey-transparent 1px;
    background-color: $choices-white;
    min-height: 18.5rem;
    min-width: fit-content;
}

.cardHeader {
    background-color: $choices-navy;
    border: solid $choices-grey-transparent 1px;
    color: $choices-white;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.25rem;
    min-height: 2rem;
    max-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.address {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $choices-grey;
    margin-top: 0.75rem;
    p {
        margin: 0;
    }
}

.cardBody {
    padding: 0 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.discountPercentage {
    text-align: center;
    width: 100%;
    color: $choices-blue;
    font-size: 2.5rem;
    margin-top: -0.25rem;
}

.discountDisclaimer {
    text-align: center;
    width: 100%;
    color: $choices-blue;
    font-size: 1.5rem;
}

.btnContainer {
    padding: 1rem;
    display: flex;
    justify-content: center;
    button {
        white-space: nowrap;
    }
}

.distance {
    color: $choices-grey;
    min-width: 100%;
    text-align: center;
    padding-top: 0.75rem;
}

.distanceText {
    padding-left: 0.5rem;
    font-weight: 600;
}

@media (min-width: 550px) {
    .cardHeader {
        min-height: 3rem;
        max-height: 3rem;
    }
}

@media (min-width:700px) {
    .cardHeader {
        padding: 0.5rem;
        font-size: 1.25rem;
    }
    .discountPercentage {
        font-size: 4rem;
    }
    .lastViewed {
        font-size: 1.1rem;
    }
    .cardBody {
        padding: 0 1.5rem
    }
}