@import '/src/util.scss';

.baseContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.5rem;
    height: 100%;
    width: 100%;
}

.form {
    background-color: $choices-white;
    display: flex;
    justify-content: center;
}

.topContent {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    max-width: 65rem;
    animation: slideInFromRight .75s ease-in-out;
}

.baseFormSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 65rem;
    animation: slideInFromLeft .75s ease-in-out;
}

.carousel {
    height: 35vw;
    max-height: 20rem;
    min-height: 15.8rem;
    width: 100%;
    background-color: $choices-navy;
}

.infoCard {
    width: 100%;
}

@media (min-width: 500px) {
    .spacer {
        min-height: 30vw;
    }
}

@media (min-width: 600px) {
    .infoCard {
        width: 90%;
    }

    .spacer {
        min-height: max(10rem, 10vw);
    }
}

@media (min-width: 800px) {
    .topContent {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(26, 1fr);
        max-height: 25rem;
    }

    .carousel {
        grid-column: 3/9;
        min-height: 24rem;
        grid-row: 1/26;
        filter: drop-shadow(0.3rem 0.3rem 0.2rem $choices-grey-transparent);
    }

    .infoCard {
        filter: drop-shadow(0.2rem 0.2rem 0.2rem $choices-grey-transparent);
        grid-column: 1/6;
        grid-row: 3/26;
        max-width: 25.6rem;
    }

    .baseFormSection {
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        grid-template-rows: repeat(32, 1fr);
    }

    .form {
        box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem $choices-grey-transparent;
        grid-column: 12/21;
        grid-row: 2/32;
        height: fit-content;
        max-width: 25.8rem;
    }

    .formImg {
        position: relative;
        z-index: -1;
        grid-column: 1/19;
        grid-row: 1/33;
        filter: drop-shadow(0.3rem 0.3rem 0.3rem $choices-grey-transparent);
    }
}


@media (min-width: 900px) {
    .baseFormSection {
        margin-bottom: 5rem;
    }

    .formImg {
        grid-column: 1/16;
        grid-row: 1/33;
    }

    .form {
        grid-column: 12/21;
        max-width: 27rem;
    }
}

@media (min-width: 1400px) {

    .topContent,
    .baseFormSection {
        min-width: 1200px;
    }

    .baseFormSection {
        padding: 3rem;
        min-height: 30rem;
    }

    .infoCard {
        max-width: 32rem;
        height: 21.5rem;
    }

    .carousel {
        min-height: 26rem;
        grid-column: 3/9;
        grid-row: 1/19;
    }

    .form {
        grid-column: 12/21; 
        grid-row: 3/30;
        min-width: 100%;
        min-height: 26rem;
    }

    .formImg {
        grid-column: 1/16;
    }
}

@media (min-width: 1600px) {
    .topContent {
        margin-left: 0%;
    }

    .topContent,
    .baseFormSection {
        min-width: 1400px;
    }

    .topContent {
        grid-template-columns: repeat(16, 1fr);
        grid-template-rows: repeat(18, 1fr);
    }

    .carousel {
        grid-column: 6/17;
        grid-row: 1/19;
        min-height: 28rem;
    }

    .infoCard {
        grid-column: 1/10;
        grid-row: 2/15;
    }

    .baseFormSection {
        grid-template-columns: repeat(16, 1fr);
        grid-template-rows: repeat(32, 1fr);
    }

    .form {
        grid-column: 11/17;
        grid-row: 2/32;
    }

    .formImg {
        grid-column: 1/14;
        grid-row: 1/33;
    }
}

@media (min-width: 1800px) {

    .topContent,
    .baseFormSection {
        min-width: 1600px;
    }

    .topContent {
        grid-template-columns: repeat(18, 1fr);
        grid-template-rows: repeat(18, 1fr);
    }

    .carousel {
        grid-column: 6/18;
        min-height: 32rem;
    }

    .infoCard {
        grid-column: 1/10;
        grid-row: 3/15;
    }

    .baseFormSection {
        padding-top: 9rem;
        grid-template-columns: repeat(18, 1fr);
    }

    .form {
        grid-column: 12/18;
        grid-row: 3/32;
    }

    .formImg {
        grid-column: 1/14;
    }
}


@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100vw);
    }

    100% {
        opacity: 1;
        transform: translateX(0vw);
    }
}

@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100vw);
    }

    100% {
        opacity: 1;
        transform: translateX(0vw);
    }
}