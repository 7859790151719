@import '/src/util.scss';

@keyframes fadeIn {
    0% {
        transform: translateY(5rem);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@mixin headerSpacing() {
    max-height: 3rem;
    font-size: max(1.2rem, 1.2vw);
    padding-bottom: 1rem;
}

@mixin columnTwoHorizontalSpacing {
    margin-left: 2rem;
}

@mixin detailsAnimation {
    animation: fadeIn 0.5s ease-in-out;
}

.detailsContainer {
    display:flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-left: 2.5%;
    max-width: max(60rem,75%);
    margin-bottom: 3rem;
}

.detailsHeaderContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.fourSquareContainer{
    display: grid;
    grid-template-rows: repeat(auto, minmax(19rem, 1fr));
    grid-template-columns: 40% 60%;
}

.returnToOffersLink {
    justify-content: start;
    @include headerSpacing();
    a {
        color: $choices-grey;
    }
}

.featuredOfferTitle {
    display: flex;
    text-transform: uppercase;
    justify-content: flex-start;
    font-weight: 800;
    color: $choices-blue;
    @include headerSpacing();
    @include columnTwoHorizontalSpacing();
    .headerTitle {
        text-transform: uppercase;
        font-weight: 800;
    }
}

.headerBtnsTopRight{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    justify-items: flex-end;
    @include headerSpacing();
}

.imgContainer {
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        align-items: center;
        box-shadow: 0px 3px 5px rgba(59, 59, 59, 0.5);
    }
}

.offerInformationContainerTopRight {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    max-width: 100%;
    justify-content: space-between;
    @include columnTwoHorizontalSpacing();
    h1 {
        color: $choices-navy;
        font-weight: 400;
        font-size: max(2rem, 3vw);
        padding: 0;
        margin: 0;
    }
}

.offerDiscount {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.discount {
    font-size: max(4em, 5vw);
    color: $choices-blue;
    font-weight: 200;
}

.offerCode {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: $choices-blue;
}

.offerDescription {
    color: $choices-grey;
    font-size: max(1.2rem, 1.2vw);
}

.offerBtns {
    display: flex;
    gap: 2rem;
    div {
        width: 8rem;
        min-width: max-content;
        padding: 0rem 0.5rem;
        background-color: $choices-white;
    }
    button {
        white-space: nowrap;
    }
}

.termsContainerBottomRight {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    grid-row-start: 3;
    @include detailsAnimation();
    @include columnTwoHorizontalSpacing();
}

.detailsContainerBottomLeft {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    grid-row-start: 3;
    @include detailsAnimation();
}

.subTitle {
    color: $choices-blue;
    font-size: max(3rem, 4vw);
    margin: 0;
    font-weight: 200;
    padding-block: 1rem;
}

@media screen and (max-width: 999px) {

    .fourSquareContainer {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: auto;
    }

    .detailsHeaderContainer{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .featuredOfferTitle{
        margin-left: 0rem;
    }

    .offerDescription{
        max-width: 77%;
    }

    .offerInformationContainerTopRight{
        margin: 0;
        min-height: none;
        max-height: none;
        justify-content: flex-start;
        grid-row-start: 2;
        h1 {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 2rem;
            font-size: max(2.5rem,6vw);
        }
    }

    .imageContainerTopLeft {
        grid-row-start: 1;
        .imgContainer{
            width: 95%;
            min-width: 327px;
            min-height: 352px;
            max-width: 490px;
            max-height: 527px;
        }
    }

    .detailsContainerBottomLeft, .termsContainerBottomRight {
        display: flex;
        flex-direction: column;
        max-width: 80%;
        @include detailsAnimation();
        margin-left: 0;
        grid-row-start: 3;
        .subTitle {
            font-size: max(3rem, 3vw);
        }
        ul{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 25px;
        }
    }

    .termsContainerBottomRight{
        grid-row-start: 4;
    }

    .detailsBody{
        margin-left: 0;
    }

    .offerBtns {
        margin-block: 1rem;
    }
  }
