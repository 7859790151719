@import '/src/util.scss';

.container {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
}

.productResults {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 0 1rem;
    gap: 1rem;
    height: 100%;
    animation: fadeIn ease-in-out .5s;
}

.resultsHeader {
    display: flex;
    flex-direction: column;
    width: 95%;
    padding-block: 0.25rem;
    justify-content: space-between;
}

.resultsBody {
    display: flex;
    width: 100%;
    height: 100%;
}

.sortContainer {
    position: relative;
    width: 21rem;
}

.summaryContainer {
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.paginationContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    z-index: 1;
}

.cardContainer {
    width: 100%;
    z-index: 1;
}

@media (min-width: 370px) {
    .cardContainer {
      flex: 1 0 33%;
    }
}

@media (min-width: 600px) {
    .productResults {
        justify-content: center;
    }
}

@media (min-width: 895px) {
    .resultsHeader {
        flex-direction: row;
    }
    .cardContainer {
        max-width: 15rem;
    }
    .productResults {
        width: 100%;
        margin-right: 5%;
        justify-content: flex-start;
        max-width: 70rem;
        align-content: flex-start;
    }
    .summaryContainer {
        padding: 0;
    }
}

@keyframes fadeIn {
    0% {
        transform: translateX(8vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0vw);
        opacity: 1;

    }
}