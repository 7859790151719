@import '/src/util.scss';

.cardContainer {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: 1rem 0;
    background-color: $choices-white;
    position: relative;
    z-index: 1;
}

.animate {
    animation: fadeIn 0.5s ease-in-out;
}

.cardTitle {
    font-weight: 200;
    font-size: 2.5rem;
    color: $choices-blue;
    margin: 0;
    padding: 0 1rem;
}

.cardBody {
    padding: 0 1rem;
    color: $choices-grey;
    a {
        color: $choices-blue;
    }
}

.cardLink {
    text-decoration: underline;
    color: $choices-grey;
   padding-left: 3rem;
}

.cardMenu {
    display: flex;
    align-items: center;
    margin:0;
    padding-top: 1rem;;
    margin-left: -1.5rem;
    div {
        width: max-content;
        max-width: max-content;
        white-space: nowrap;
    }
}

@media (min-width: 800px) {
    .cardContainer {
        justify-content: space-between;
        padding: 1rem 0.5rem 2rem 0.5rem;
        max-width: 30rem;
    }
}

@media (min-width: 1400px) {
    .cardTitle {
        font-size: 3.5rem;
        padding: 0 2rem;
    }
    .cardBody {
        padding: 0 2rem;
    }
    .cardMenu {
        margin: -0.5rem 0 0 0;
    }
    .cardBody, .cardLink {
        font-size: 1.2rem;
    }
}

@keyframes fadeIn {
    0%{
        opacity: 0;
        transform: translateX(-3rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0rem);
    }
}