@import '/src/util.scss';

.banner {
    display:none;
    width: 100vw;
    background-color: $choices-blue;
    padding: 0.5rem;
    font-size: 1.2rem;
    z-index: 3;
    position: relative;
    a { 
        text-align: right;
        width: 97%;
        text-decoration: none;
        color: $choices-white;
    }
}

@media (min-width: 900px) {
    .banner {
        display: flex;
    } 
}