@import '/src/util.scss';


.modal {
    width: 100%;
    height: 100%;
    padding: 0 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15;
}

.modalContainer {
    min-width: 265px;
    width: 50%;
    max-width: 500px;
    height: 50%;
    padding: 1rem;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 1rem;

    position: relative;
    box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem $choices-grey-transparent;
}

.modalHeaderContainer {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}

.modalHeader {
    margin: 0;
    font-size: 2rem;
    font-weight: 300;
    color: $choices-navy;
}

.modalFormContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
}

.modalForm {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.modalButtonCompleteRight,
.modalButtonComplete {
    margin-top: .75rem;
    z-index: 3;
}

.modalButtonCompleteRight {
    background-color: $choices-navy;
}
.modalButtonCompleteRight .btn{
    background-color: $choices-navy;
}

.btnBlue{
    background-color: $choices-home-blue;
    color: $choices-white
}
.logoMark {
    width: 50%;
    overflow: hidden;
    position: absolute;
    right: 0;
    bottom: -4px;
    z-index: 1;
}
@media (min-width: 1300px) {
    .modalContainer {
        padding: 2rem;
    }
}
@media (min-width: 800px) {
    .modalSpan {
        display: none;
    }
}

@media (max-width: 750px) {
    .modal {
        padding: 0;
    }
}