@import '/src/util.scss';

.practitionerMeta {
    display: flex;
    flex-direction: column;
    color: $choices-grey;
    font-size: max(1.2rem, 1.2vw);
}

.metaDetails {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.metaColumn {
    display: flex;
    flex-direction: column;
    p {
        margin: 0.5rem 0;
        padding-bottom: 0.5rem;
    }
}

.specialties {
    margin: 0.5rem 0 0 0;
}
