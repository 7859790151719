// Colors
$choices-navy: #022F47;
$choices-navy-dark: #001C32;

$choices-white: #ffff;
$choices-white-transparent: rgba(255, 255, 255, 0.25);
$choices-grey-highlight: rgb(231, 229, 229);
$choices-grey: #58595B;
$choices-grey-transparent: #58595b60;
$choices-grey-dark-transparent: #00000091;
$choices-grey-light: #ccc;
$choices-grey-highlight: rgb(231, 229, 229);
$choices-white: #ffff;
$choices-white-transparent: rgba(255, 255, 255, 0.25);
$choices-red: #D33E43;
$choices-red-transparent: rgba(255, 0, 0, 0.288);

$choices-green: #4fa76c;

$choices-blue: #0076BE;
$choices-blue-90P: rgba(0, 117, 190, 0.9);
$choices-blue-transparent: rgba(0,118,190, 0.25);
$choices-home-bright-blue: rgba(0, 117, 190, 0.9);
$choices-home-blue: rgba(9, 104, 163, 0.9);
$choices-home-faded-blue: rgba(31, 98, 139, 0.9);
$choices-home-dark-blue: rgba(17, 82, 121, 0.9);

$choices-input-border: #999999;
$choices-input-fill: #F8F8F8;


// Titles & Headers:
$font-Xsmall: 1rem;
$font-small: 1.25rem;
$font-med-small: 1.5rem;
$font-med: 2rem;
$font-large: 3rem;
$font-Xlarge: 3.5rem;
$font-XXlarge: 5rem;

@mixin choices-title($title-color, $title-size, $title-weight) {
    font-weight: $title-weight;
    font-size: $title-size;
    color: $title-color;
}

