@import '/src/util.scss';

.imgContainer {
    max-width: 100vw;
    height: auto;
}

.img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media (min-width: 800px) {
    .container {
        height: 28rem;
    }
    .accessDenied {
        height: 38rem;
    }
}

@media (min-width: 900px) {
    .accessDenied {
        height: 37rem;
        min-width: 85vw
    }
}

@media (min-width: 1100px) {
    .accessDenied {
        min-width: 75vw
    }
}

@media (min-width: 1300px) {
    .accessDenied {
        min-width: 70vw
    }
}

@media (min-width: 1400px) {
    .container {
        height: 30rem;
    }
    .accessDenied {
        height: 39rem;
        min-width: 70vw
    }
}

@media (min-width: 1800px) {
    .container {
        height: 34rem;
    }
    .accessDenied {
        height: 40rem;
        min-width: 65vw
    }
}


