@import '/src/util.scss';

.form {
    height: min-content;
    padding: 2rem;
    background-color: $choices-white;
    z-index: 3;
    max-width: min(35rem, 90%);
    margin-top: 4rem;
    animation: fadeIn 0.5s ease-in-out;
}

.inputContainer {
    margin-top: 0.5rem;
    input {
        max-width: 15rem;
    }
}

.info {
    color: $choices-grey;
    font-size: max(1rem, 1vw);
    font-style: italic;
}

.btnContainer {
    margin-top: 1rem;
    div {
        min-width: max-content;
        padding: 0.5rem 1rem;
    }
}

@mixin sendEmailMessage {
    display: flex;
    max-width: 90%;
    font-size: max(1rem, 1vw);
    margin-top: 0.5rem;
}

.hidden {
    visibility: hidden;
    @include sendEmailMessage();
}

.errorMessage {
    visibility: visible;
    color: $choices-red;
    @include sendEmailMessage();
}

.successMessage {
    visibility: visible;
    color: $choices-grey;
    @include sendEmailMessage();
}

.animate {
    animation: fadeIn 0.5s ease-in-out;
}

@media (min-width: 600px) {
    .form {
        border: solid $choices-grey-transparent 1px;
        margin-top: 12rem;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}