@import '/src/util.scss';

.pageContainer {
    height: max-content;
    margin-bottom: 3rem;
    min-height: 65vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
}

@media (min-width: 895px) {
    .pageContainer {
        align-items: flex-start;
        padding: 0 2rem;
    }
}
