@import '/src/util.scss';

.cardContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border: solid $choices-grey-transparent 1px;
    background-color: $choices-white;
    min-height: 15rem;
    min-width: fit-content;
}

.cardHeader {
    background-color: $choices-navy;
    border: solid $choices-grey-transparent 1px;
    color: $choices-white;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.25rem;
    padding: 0.25rem;
}

.cardBody {
    padding: 0 0.5rem;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

}

.discountPercentage {
    text-align: center;
    width: 100%;
    color: $choices-blue;
    font-size: 2.5rem;
    margin-top: -0.25rem;
}

.discountDisclaimer {
    text-align: center;
    width: 100%;
    color: $choices-blue;
    font-size: 1.5rem;
}

.btnContainer {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        white-space: nowrap;
    }
}

.practitionerName {
    color: $choices-blue;
    font-weight: 700;
    min-width: 100%;
    text-align: center;
    padding-top: 0.5rem;
    font-size: 1.1rem;
}

.distance {
    color: $choices-grey;
    min-width: 100%;
    text-align: center;
    padding-top: 0.5rem;
}

.distanceText {
    padding-left: 0.5rem;
    font-weight: 600;
}

.practitionerMetadata {
    display: none;
    padding-top: 0.5rem;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    color: $choices-grey;
    text-align: center;
}

.metadataHeader {
    font-weight: 600;
}

@media (min-width:700px) {
    .practitionerMetadata {
        display: flex;
    }
    .cardHeader {
        padding: 0.5rem;
    }
    .discountPercentage {
        font-size: 4rem;
    }
    .lastViewed {
        font-size: 1.1rem;
    }
    .cardBody {
        padding: 0 1.5rem
    }
}