@import '/src/util.scss';

.accountPageContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: minmax(220px, 20%) minmax(50%, 400px) 1fr;
    padding: 5rem 1rem 10rem 1rem;
}

.accountContentContainer {
    grid-column: 2 / 3;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    background: $choices-white;
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(88, 89, 91, 0.376);
    animation: slideIn .25s ease-out;
}

.accountContentTitle {
    width: 100%;
    margin: 0;
    padding: 0 0 2rem 0;
    color: $choices-blue;
    font-size: 4rem;
    font-weight: 100;
}

.accountInfoContainer {
    width: 100%;
}

@media(max-width: 650px) {

    .accountContentContainer {
        flex-direction: column;
    }

    .managePlanInfoContainer {
        width: 100%;
    }
}

@media(max-width: 900px) {
    .accountPageContainer {
        grid-template-columns: minmax(220px, 20%) minmax(435px, auto);
    }
}

@media(max-width: 750px) {
    .accountPageContainer {
        grid-template-columns: auto;
    }

    .accountContentContainer {
        grid-column: 1/3;
    }
}

@media(max-width: 450px) {
    .accountContentTitle {
        font-size: 3rem;
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(40rem);
    }

    100% {
        opacity: 1;
    }
}