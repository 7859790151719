@import '/src/util.scss';

.btn {
    background: none;
    border: none;
}

.paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    margin: 0;
    padding: 1rem;
    margin-right: 3%;
}

@mixin hover() {
    cursor: pointer;
    transform: scale(110%);
    transition: all .2s linear;
}

.btn:hover {
    @include hover();
}

.pageNumbers {
    display: flex;
}

.pageNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: $choices-navy-dark;
    background: none;
    border: none;
    width: 3rem
}

.pageNumber:hover {
    @include hover();
}

.highlight {
    background: $choices-grey-highlight;
}

.disabled {
    opacity: 0.25;
    border: none;
    background: none;
}