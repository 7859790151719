@import '/src/util.scss';

.form {
    width: 100%;
    padding: 1rem;
    max-width: 30rem;
    min-width: 23rem;
}

.formInputs {
    width: 90%;
}

.formItem {
    display: flex;
    justify-content: space-between;
}

.inputContainer {
    max-width: 48%;
}

.formMenu {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.formBtns {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    a {
        padding-left: 1rem;
        color: $choices-grey; 
        font-size: max(1.2rem, 1vw);
    }
}

.message {
    display: flex;
    justify-content: center;
    margin: 0 2rem;
    width: 100%;
    height: 100%;
}

.hidden {
    visibility: hidden;
}

@mixin animation() {
    animation: fadeIn 0.5s ease-in-out;
}

.success {
    color: $choices-green;
    @include animation();
}

.error {
    color: $choices-red;
    @include animation();
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 1400px) {
    .form {
        padding: 1rem 2rem;
    }
}
